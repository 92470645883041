import React from "react";
import { Sidebar } from "../../components/user-dashboard/sidebar";
import { Header } from "../../components/user-dashboard/header";
import { UserDashboard } from "../user-dashbard/user-dashboard-pages/dashboard";
import { ROLES } from "../../app/utility/app-codes";
import UsersList from "../admin-dashboard/users/usersList";
import ManageCourseList from "../support-dashboard/ManageCourses/manageCourseList";

export const Dashboard = () => {
  const userdata: any = sessionStorage.getItem("loginData");
  const userRole = JSON.parse(userdata);
  return (
    <div className="container-fluid ">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg">
          {ROLES.Admin === userRole?.role?.roleName && <UsersList />}
          {ROLES.User === userRole?.role?.roleName && <UserDashboard />}
          {ROLES.Support === userRole?.role?.roleName && <ManageCourseList />}
        </div>
      </div>
    </div>
  );
};
