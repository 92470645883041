import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/home";
import { HomePage } from "../pages/home-page";
import { AboutUs } from "../pages/about-us";
import { Programs } from "../pages/programs";
import { Ambassador } from "../pages/ambassador";
import { ProgramDetails } from "../pages/program-details";
import { SignIn } from "../pages/sign-in";
import { Dashboard } from "../pages/dashboard/Dashboard";
import UserProfile from "../pages/user-dashbard/user-dashboard-pages/userProfile";
import UserTraining from "../pages/user-dashbard/user-dashboard-pages/userTraining";
import UserProjects from "../pages/user-dashbard/user-dashboard-pages/userProjects";
import UserInternships from "../pages/user-dashbard/user-dashboard-pages/userInternships";
import Usercertificates from "../pages/user-dashbard/user-dashboard-pages/usercertificates";
import MeetsList from "../pages/support-dashboard/meets/meetsList";
import PaymentsList from "../pages/admin-dashboard/payments/paymentsList";
import CreateUser from "../pages/admin-dashboard/users/createUser";
import CreateMeet from "../pages/support-dashboard/meets/createMeet";
import RegisterNow from "../pages/registerNow";
import AllDomainHome from "../components/allDomainHome";
import GetStarted from "../pages/getStarted";
import CreateCourse from "../pages/support-dashboard/ManageCourses/createCourse";
import CoursesList from "../pages/support-dashboard/ManageCourses/manageCourseList";
import UserCalendar from "../pages/user-dashbard/user-dashboard-pages/calendar";
import Cms from "../pages/user-dashbard/user-dashboard-pages/cms";
import RegisterList from "../pages/admin-dashboard/Register/RegisterList";
import StudentsList from "../pages/admin-dashboard/Students Data/StudentsList";
import AmbassadorList from "../pages/admin-dashboard/ambassador/ambassadorList";
import Terms from "../components/Conditions/Terms";
import Refund from "../components/Conditions/Refund";
import Privacy from "../components/Conditions/privacy";
import CreateLmsVideos from "../pages/support-dashboard/LmsVideos/CreateLmsVideos";
import LmsVideoList from "../pages/support-dashboard/LmsVideos/LmsVideoList";
import CertificateList from "../pages/support-dashboard/certificate/certificateList";
import CreateCertificate from "../pages/support-dashboard/certificate/createCertificate";
import EditUser from "../pages/admin-dashboard/users/EditUser";
import CreateAmbassador from "../pages/admin-dashboard/ambassador/createambassador";
import CreateRegisterNow from "../pages/admin-dashboard/Register/createRegisterNow";
import EditMeet from "../pages/support-dashboard/meets/editMeet";
import QueriesList from "../pages/admin-dashboard/queries/queriesList";
import EditCourse from "../pages/support-dashboard/ManageCourses/editCourse";
import ProjectsList from "../pages/support-dashboard/project/projectList";
import CreateProject from "../pages/support-dashboard/project/createProject";
import CgpaList from "../pages/support-dashboard/students/CgpaStudentsList";
import ForPassword from "../pages/user-dashbard/user-dashboard-pages/ForPassword";
import CgpaAccess from "../pages/support-dashboard/students/CgpaAccess";
import CareerGuidances from "../pages/support-dashboard/students/CareerGuidances";
import CareerGuidancesAccess from "../pages/support-dashboard/students/CareerGuidancesAccess";

export const RootNavigation = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/programs" element={<Programs />}></Route>
        <Route path="/alldomains" element={<AllDomainHome />}></Route>
        <Route path="/ambassador" element={<Ambassador />}></Route>
        <Route path="/program-details" element={<ProgramDetails />}></Route>
        <Route path="/sign-in" element={<SignIn />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/user/profile" element={<UserProfile />}></Route>
        <Route path="/user/training" element={<UserTraining />}></Route>
        <Route path="/user/projects" element={<UserProjects />}></Route>
        <Route path="/user/internships" element={<UserInternships />}></Route>
        <Route path="/user/calendar" element={<UserCalendar />}></Route>
        <Route path="/user/cms" element={<Cms />}></Route>
        <Route path="/user/certificates" element={<Usercertificates />}></Route>
        <Route path="/admin/meetslist" element={<MeetsList />}></Route>
        <Route path="/admin/paymentslist" element={<PaymentsList />}></Route>
        <Route path="/admin/createuser" element={<CreateUser />}></Route>
        <Route path="/support/createmeet" element={<CreateMeet />}></Route>
        <Route path="/support/editCourse" element={<EditCourse />}></Route>
        <Route path="/registernow" element={<RegisterNow />}></Route>
        <Route path="/getstarted" element={<GetStarted />}></Route>
        <Route path="/admin/prospectlist" element={<RegisterList />}></Route>
        <Route path="/admin/createregisternow" element={<CreateRegisterNow />}></Route>
        <Route path="/admin/queries" element={<QueriesList />}></Route>


        <Route
          path="/admin/ambassadordata"
          element={<AmbassadorList />}
        ></Route>
          <Route
          path="/admin/createambassador"
          element={<CreateAmbassador />}
        ></Route>
        <Route path="/register/students" element={<StudentsList />}></Route>
        <Route path="/support/meetslist" element={<MeetsList />}></Route>
        <Route path="/support/projectlist" element={<ProjectsList />}></Route>
        <Route path="/support/createproject" element={<CreateProject />}></Route>
        <Route path="/support/cgpalist" element={<CgpaList />}></Route>
        <Route path="/support/cgpaAccess" element={<CgpaAccess />}></Route>
        <Route path="/support/careerGuidances" element={<CareerGuidances />}></Route>
        <Route path="/support/careerGuidancesAccess" element={<CareerGuidancesAccess />}></Route>

        <Route path="/support/createmeet" element={<CreateMeet />}></Route>
        <Route path="/support/editmeet" element={<EditMeet />}></Route>
        <Route path="/support/createcourse" element={<CreateCourse />}></Route>
        <Route path="/support/courseslist" element={<CoursesList />}></Route>
        <Route path="/terms" element={<Terms />}></Route>
        <Route path="/forpassword" element={<ForPassword />}></Route>
        <Route path="/refund" element={<Refund />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route
          path="/support/createlmscourses"
          element={<CreateLmsVideos />}
        ></Route>
        <Route path="/support/lmsvideolist" element={<LmsVideoList />}></Route>
        <Route
          path="/support/certificatelist"
          element={<CertificateList />}
        ></Route>
        <Route
          path="/support/createcertificate"
          element={<CreateCertificate />}
        ></Route>
        <Route path="/admin/editUser" element={<EditUser />}></Route>
      </Routes>
    </>
  );
};
