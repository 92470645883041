import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { StudentsDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const StudentsList = () => {
  const [name, setName] = useState("");
  const [StudentsData, setStudentsData] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]); // State for filtered data
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    setFilteredStudents(StudentsData); // Reset to full list
  };

  const handleSearch = () => {
    const filteredData = StudentsData.filter((student: any) =>
      student.firstName.toLowerCase().includes(name.toLowerCase()) ||
      student.lastName.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredStudents(filteredData);
  };

  useEffect(() => {
    getRegisters();
  }, []);

  const getRegisters = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllPaymentList(data).then((res: any) => {
      if (res.status === "Success") {
        setStudentsData(res.data);
        setFilteredStudents(res.data); // Initialize filtered data
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleAccessData = (data: any) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      courseName: data.courseName,
      phone: data.phone,
    };
    AdminService.userAccess(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };

  const deletePaymentInfo = (userId: string) => {
    const payload = {
      userId: userId,
    };
    AdminService.deletePayment(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b> Users List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredStudents} // Use filtered data here
                TableCols={StudentsDataGridCols}
                handleAccessData={handleAccessData}
                deletePaymentInfo={deletePaymentInfo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsList;
