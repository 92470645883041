import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  BsFillGridFill,
  BsPersonFill,
  BsBook,
  BsBriefcaseFill,
  BsLaptop,
  BsAwardFill,
} from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import { MdPayment } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { FaBook } from "react-icons/fa";

export const Sidebar = () => {
  const [sidebarMenu, setSidebarMenu] = useState<any[]>([]);

  useEffect(() => {
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    getSidebarMenuList(data?.role?.roleName);
  }, []);

  let getSidebarMenuList = (role: string) => {
    let list: any = [];
    if (role === "user") {
      list = [
        {
          name: "Dashboard",
          route: "/dashboard",
          icon: BsFillGridFill,
        },
        {
          name: "Career Guidance",
          route: "/user/training",
          icon: BsBook,
        },
        {
          name: "CGPA Building",
          route: "/user/projects",
          icon: BsBriefcaseFill,
        },
        {
          name: "Internships",
          route: "/user/internships",
          icon: BsLaptop,
        },
        {
          name: "Certificates",
          route: "/user/certificates",
          icon: BsAwardFill,
        },
        {
          name: "LMS",
          route: "/user/cms",
          icon: FaBook,
        },
      ];
    } else if (role === "admin") {
      list = [
        {
          name: "Eduexpose Interns",
          route: "/dashboard",
          icon: BsPersonFill,
        },
        {
          name: "Prospect List",
          route: "/admin/prospectlist",
          icon: BsPersonFill,
        },
        {
          name: "Ambassador Data",
          route: "/admin/ambassadordata",
          icon: BsPersonFill,
        },
        {
          name: "Registered Students",
          route: "/register/students",
          icon: BsPersonFill,
        },
        {
          name: "Subscribers",
          route: "/admin/queries",
          icon: BsPersonFill,
        },
        // icon: SiGooglemeet ,

        // {
        //   name: "Internships",
        //   route: "/user/internships",
        //   icon: BsLaptop,
        // },
        // {
        //   name: "Certificates",
        //   route: "/user/certificates",
        //   icon: BsAwardFill,
        // },
      ];
    } else if (role === "support") {
      list = [
        {
          name: "Manage Courses",
          route: "/dashboard",
          icon: BsLaptop,
        },
        {
          name: "Certificates",
          route: "/support/certificatelist",
          icon: BsAwardFill,
        },
        {
          name: "LMS Videos",
          route: "/support/lmsvideolist",
          icon: BsPersonFill,
        },
        {
          name: "Project",
          route: "/support/projectlist",
          icon: BsPersonFill,
        },
        {
          name: "Student CGPA",
          route: "/support/cgpalist",
          icon: BsPersonFill,
        },
        {
          name: "Career Guidances",
          route: "/support/careerGuidances",
          icon: BsPersonFill,
        },
        {
          name: "Google Meet List",
          route: "/support/meetslist",
          icon: SiGooglemeet,
        },
      ];
    }
    setSidebarMenu(list);
  };

  return (
    <div className="primary-bg text-white">
      <div className="sidebar_a">
        <ul className="nav-list px-0 pt-4">
          {sidebarMenu.map((data: any, index: number) => (
            <NavLink
              key={index}
              to={data.route}
              className="text-decoration-none text-white sidemenu-list-item"
            >
              <li className="py-2 pe-5 text-start ps-3 py-3 sidemenu-item px-4 border-bottom border-white">
                <data.icon className="me-2" />
                &nbsp; &nbsp;&nbsp;
                {data.name}
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};
