import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import { UserService } from "../../../app/service/user.service";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import intenImg from "../../../assets/images/cert/internship (1).jpg";
import trainImg from "../../../assets/images/cert/training.jpg";
import parImg from "../../../assets/images/cert/Participation.jpg";

const Usercertificates = () => {
  const [tableData, setTableData] = useState([]);

  const dateFormat = (dateStr: any) => {
    const date = moment(dateStr);
    const formattedDate = date.format("DD-MMM-YYYY");
    return formattedDate;
  };

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole.email;
    const data = {
      email: email,
    };

    // showLoader();

    UserService.certificatesList(data)
      .then((res: any) => {
        if (res.status === "Success") {
          setTableData(res.data);
        } else {
          // toast.error(res.message);
        }
        // hideLoader();
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
        // hideLoader();
      });
  };
  const handleView = async (item: any, type: any) => {
    let certificateHtml: any;

    switch (type) {
      case "InternshipCertificate":
        certificateHtml = ReactDOMServer.renderToString(IntenshipC(item));
        break;
      case "TrainingCertificate":
        certificateHtml = ReactDOMServer.renderToString(IntenshipT(item));
        break;
      case "ParticipationCertificate":
        certificateHtml = ReactDOMServer.renderToString(IntenshipP(item));
        break;
      default:
        certificateHtml = "";
    }

    const container1 = document.createElement("div");
    container1.innerHTML = certificateHtml;
    document.body.appendChild(container1);

    const canvas = await html2canvas(container1, {
      backgroundColor: null,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [700, 700],
    });

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    pdf.setFillColor(29, 19, 15);

    pdf.rect(0, 0, pageWidth, pageHeight, "F");

    const imageWidthPx = canvas.width;
    const imageHeightPx = canvas.height;

    const imageWidthPt = imageWidthPx * 0.75;
    const imageHeightPt = imageHeightPx * 0.75;

    const topMargin = 40;
    const yPos = topMargin;

    const xPos = (pageWidth - imageWidthPt) / 2;

    pdf.addImage(imgData, "PNG", xPos, yPos, imageWidthPt, imageHeightPt);

    pdf.save("certificate.pdf");
  };
  const IntenshipC = (item: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    return (
      <div className="certificate-wrapper">
        <img src={intenImg} alt="Internship Certificate" />
        <div className="intenship-name">{item.userName}</div>
        <div className="intenship-text-info">
          Participated in {item.courseName} from {startDate} to {endDate} and
          successfully completed the program.
        </div>
        <div className="intenship-date">{date}</div>
        <div className="intenship-person-name">SRIVASTAV</div>
      </div>
    );
  };
  const IntenshipT = (item: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    return (
      <div className="training-certificate-wrapper">
        <img src={trainImg} alt="Internship Certificate" />
        <div className="training-name">{item.userName}</div>
        <div className="training-text-info">
          Participated in {item.courseName} from {startDate} to {endDate} and
          successfully completed the program.
        </div>
      </div>
    );
  };
  const IntenshipP = (item: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = dateFormat(item.startDate);
    const endDate = dateFormat(item.endDate);
    return (
      <div className="par-certificate-wrapper">
        <img src={parImg} alt="Internship Certificate" />
        <div className="par-name">{item.userName}</div>
        <div className="par-text-info">
          Participated in {item.courseName} from {startDate} to {endDate} and
          successfully completed the program.
        </div>
        <div className="par-date">{date}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg mt-5 px-5">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ color: "white" }} className="primary-bg">
                    Domain Name
                  </th>
                  <th style={{ color: "white" }} className="primary-bg">
                    Internship Period
                  </th>
                  <th style={{ color: "white" }} className="primary-bg">
                    Issue Dates
                  </th>
                  <th style={{ color: "white" }} className="primary-bg">
                    Certificate Types
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.courseName}</td>
                      <td>{dateFormat(item.startDate)} to {dateFormat(item.endDate)}</td>
                      <td>{dateFormat(item.createdAt)}</td>
                      <td>
                        {item.certificateType.map((type: string, i: number) => (
                          <>
                            <button
                              key={i}
                              className="btn btn-success mt-2"
                              onClick={() => handleView(item, type)}
                            >
                              {type}
                            </button>
                            <br />
                          </>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usercertificates;
