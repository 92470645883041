import React from "react";
import Students from "./../../assets/images/acquire-skills/Students.png";
import Google from "./../../assets/images/acquire-skills/google.png";
import Glassdoor from "./../../assets/images/acquire-skills/glassdoor.png";
import Indeed from "./../../assets/images/acquire-skills/indeed.png";
import Facebook from "./../../assets/images/acquire-skills/facebook.png";
import Kununu from "./../../assets/images/acquire-skills/kununu.png";
import tcs from "./../../assets/images/companies/tcs.png";
import hcl from "./../../assets/images/companies/hcl.png";
import cts from "./../../assets/images/companies/congizant.png";
import techmahendra from "./../../assets/images/companies/techmahendra.png";
import microsoft from "./../../assets/images/companies/microsoft.png";
import infoys from "./../../assets/images/companies/infoys.png";
import HomeVideo from "./../../assets/images/home_video.png";
import mphasis from "./../../assets/images/companies/mphasis.png";
import { useNavigate } from "react-router-dom";

export const AcquireSkills = () => {
  const videoUrl = "https://www.youtube.com/watch?v=7WrHNF9KKso";
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/programs");
  };
  return (
    <div className="col-md-12">
      <div className="hero-section">
        <span className="primary-color">Acquire skills</span> today that will
        remain
        <br /> relevant and valuable in the{" "}
        <span className="primary-color">future.</span>{" "}
      </div>
      <div className="secondary-color my-5">
        <div>
          <img src={Students} className="students-image" />
        </div>
        {/* <div>
                    Join <span className='text-white fw-bold'>100+</span> students who have already signed up
                </div> */}
      </div>
      <div className="my-5">
        <button
          className="bg-white px-3 py-2 secondary-btn"
          onClick={handleClick}
        >
          Explore Programs
        </button>
      </div>
      {/* <video controls autoPlay className='h-50 mb-5 acquire-skills-video'>
                <source src={videoUrl} type="video/mp4" />
            </video> */}
      <div className="">
        <img src={HomeVideo} className="h-50 mb-5 acquire-skills-video" />
      </div>
       {/* <div className='row d-md-flex justify-content-between mx-2 mx-md-5 mb-5'>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Google} alt="" /></span><span>Google</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Facebook} alt="" /></span><span>Facebook</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Glassdoor} alt="" /></span><span>Glassdoor</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Indeed} alt="" /></span><span>Indeed</span></div>
                </div>
                <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-3 px-md-5 py-1 py-md-3'>
                    <div>Rated 5/5</div>
                    <div className='py-1'><span className='me-2 '><img src={Kununu} alt="" /></span><span>Kununu</span></div>
                </div>
            </div>  */}
      {/* <div className='px-2 mx-md-5'>
                <div className='text-center mb-5'>
                    <div className='fs-2 fw-bold'>Our <span className='primary-color'>Alumini Partners</span> works at</div>
                </div>
                <div className='row d-md-flex justify-content-between mx-2 mx-md-5 mb-5'>
                    <div className='col-5 col-md-2'>
                        <img src={tcs} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={hcl} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={microsoft} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={infoys} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={techmahendra} alt="" />
                    </div>

                   
                </div>
            </div> */}
    </div>
  );
};
