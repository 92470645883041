import React, { useEffect, useState } from "react";
import FooterLogo from "./../assets/images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { domains } from "../courseConfig";
import { toast } from "react-toastify";
import { UserService } from "../app/service/user.service";
import { AdminService } from "../app/service/admin.service";

export const Footer = () => {
  const navigate = useNavigate();
  const [displayCount, setDisplayCount] = useState(3);
  const [email, setEmail] = useState("");
  const [courseData, setCourseData] = useState<any>([]);


  useEffect(() => {
    scrollToTop();
  getCoursesList(); 
    
  }, []);

  const getCoursesList = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getCourseList(data).then((res: any) => {
      if (res.status === "Success") {
        setCourseData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClick = (domain: any) => {
    scrollToTop();
    navigate(`/program-details`, { state: domain });
  };
  const handleLoadMore = () => {
    setDisplayCount(domains.length);
  };
  const handleSubscribe = () => {
    let payload = {
      email: email,
    };
    UserService.createQueries(payload).then((res) => {
      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success(res.message);
        setEmail('');
      }
    });
    setEmail('');
  };
  return (
    <div className="px-2 mx-md-5">
      <div className="row mb-4 mb-md-5">
        <div className="col-4 col-md-3 col-lg-2">
          <div className="fs-5 fw-bold text-white text-start ms-md-3 my-2">
            Menu
          </div>
          <div className="text-start ps-0 ps-md-3">
            <Link to="/" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Home</h6>
            </Link>
            <Link to="/about-us" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">About us</h6>
            </Link>
            <Link to="/programs" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Programs</h6>
            </Link>
            <Link to="/ambassador" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Ambassador</h6>
            </Link>
            <Link to="/sign-in" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Sign in</h6>
            </Link>
          </div>
        </div>
        <div className="col-4 col-md-3 col-lg-2">
          <div className="fs-5 fw-bold text-white text-start ms-md-3 my-2">
            Explore Programs
          </div>
          <div className="text-start ps-0 ps-md-3">
            {courseData.slice(0, displayCount).map((domain: any, index: any) => {
              return (
                <div className="footer-data" key={index}>
                  <h6
                    className="footer-menu-list-item"
                    onClick={() => handleClick(domain)}
                  >
                    {domain.courseName}
                  </h6>
                </div>
              );
            })}
            {displayCount < courseData.length && (
              <div className="footer-data">
                <h6 className="footer-menu-list-item" onClick={handleLoadMore}>
                  More..
                </h6>
              </div>
            )}
          </div>
        </div>
        <div className="col-4 col-md-3 col-lg-2">
          <div className="fs-5 fw-bold text-white text-start ms-md-3 my-2">
            Contact
          </div>
          <ul className="text-start ps-0 ps-md-3">
            <li className="list-style-type-none footer-menu-list-item">
              madhapur, Hyderabad, Telangana, 500018
            </li>
            <li className="list-style-type-none footer-menu-list-item d-none d-md-block">
              support@eduexpose.in
            </li>
            <li className="list-style-type-none footer-menu-list-item d-block d-md-none">
              support@edu
              <br />
              expose.in
            </li>
            <li className="list-style-type-none footer-menu-list-item">
              +91 7207706919
            </li>
          </ul>
        </div>
        <div className="col-6 col-md-3 col-lg-2 mb-3">
          <div className="fs-5 fw-bold text-white text-start ms-md-3 my-2">
            Documents
          </div>
          {/* <ul className="text-start ps-0 ps-md-3">
            <li className="list-style-type-none footer-menu-list-item">
              
            </li>
            <li className="list-style-type-none footer-menu-list-item d-none d-md-block">
              <a
                href="/pdfs/terms-and-conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="list-style-type-none footer-menu-list-item text-decoration-none"
              >
                Privacy Policies
              </a>
            </li>
            <li className="list-style-type-none footer-menu-list-item d-none d-md-block">
              <a
                href="/pdfs/terms-and-conditions.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="list-style-type-none footer-menu-list-item text-decoration-none"
              >
                Refund Policies
              </a>
            </li>
          </ul> */}
          <div className="text-start ps-0 ps-md-3">
            <Link to="/terms" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Terms & Conditions</h6>
            </Link>
            <Link to="/privacy" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Privacy Policies</h6>
            </Link>
            <Link to="/refund" className="mt-5 footer-data">
              <h6 className="footer-menu-list-item">Refund Policies</h6>
            </Link>
          </div>
        </div>
        <div className="col-10 col-md-5 mt-0 mt-md-3 col-lg-4">
          <div className="card border border-white rounded-0 text-white p-3 bg-transparent">
            <div className="text-start fs-5">Subscribe for regular updates</div>
            <div className="d-md-flex my-2">
              <div className="w-75">
                <label className="text-start d-flex justify-content-start form-label">
                  Email Address
                </label>
                <input
                  type="text"
                  className="border-0 border-bottom form-control custom-input"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="mt-4 pt-2 mx-md-4 text-start">
                <button
                  className="px-3 py-1 bg-transparent text-white border border-white primary-btn"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row text-dark my-3 my-md-5 px-3 px-md-0">
        <div className="col-md-11 mx-auto py-3 rounded-3 bg-white h-50">
          <div className="d-flex justify-content-between mx-0 mx-sm-3">
            <div className="text-dark ">
              <div className="col-10 col-md-7">
                <img src={FooterLogo} className="img-fluid" />
              </div>
              <div className="text-start">Copyrights © eduexpose</div>
            </div>
            <div className="col-3 col-md-2 col-lg-1 text-dark d-flex flex-column">
              <div className="fs-5 text-start d-flex justify-content-start me-auto">
                Socials
              </div>
              <div className="d-flex">
              <a href="https://www.facebook.com/profile.php?id=61564173928500" target="_blank">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/edu_xpose?igsh=Y3JoY3VpYmt5MTEx&utm_source=qr" target="_blank">
                  <i className="bi bi-instagram mx-2 mx-md-3"></i>
                </a>
                <a href="https://www.linkedin.com/company/eduexpose-in/" target="_blank">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
