import React, { useState } from "react";
import SignInImage from "./../assets/images/sign-in.png";
import { NavBar } from "../components/nav-bar";
import { Footer } from "../components/footer";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserService } from "../app/service/user.service";
import { useAppContext } from "../context/AppContext";

export const SignIn = () => {
  const {showLoader,hideLoader}:any=useAppContext();
  const [signInData, setSignInData] = useState<any>({
    email: "",
    password: "",
  });
  const navigation = useNavigate();
  const [loginError, setLoginError] = useState<any>("");

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setSignInData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSignIn = async () => {
    let payload = {
      email: signInData?.email,
      password: signInData?.password,
    };
    showLoader();
    await UserService.userLogin(payload)
      .then((res: any) => {
        if (res?.status === "Success") {
          toast.success(res.message);
          sessionStorage.setItem("loginData", JSON.stringify(res.data));
          hideLoader();
          if (res?.data?.userKey) {
            navigation("/forpassword");
          } else {
            navigation("/dashboard");
          }
        } else {
          hideLoader();
          toast.error(res.message);
        }
      })
      .catch((err: any) => {
        console.log(err);
        hideLoader();
      });
  };
  
  return (
    <div className="container-fluid scroll-bar-width-none primary-bg text-white pt-5">
      <div className="row">
        <div className="col-md-12">
          <NavBar activeMenu={"home"} />
        </div>
        <div className="col-md-12 px-3 mx-md-0">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-10 col-md-11 mx-auto pt-5">
                  <div className="fs-14 secondary-color my-2 text-start">
                    Welcome back!
                  </div>
                  <div className="fs-2 fw-bold my-3 text-start">
                    Sign in fellow{" "}
                    <span className="primary-color">achiever</span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-flex justify-content-start">
                      User ID
                    </label>
                    <input
                      type="text"
                      name="email"
                      className="form-control custom-input bg-transparent border-0 border-bottom rounded-0 text-white"
                      defaultValue={signInData.email}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-flex justify-content-start">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control custom-input bg-transparent border-0 border-bottom rounded-0 text-white"
                      defaultValue={signInData.password}
                      onChange={handleInput}
                    />
                  </div>
                  <div className="my-2 text-start">
                    <input
                      type="checkbox"
                      className="me-2 bg-transparent border border-secondary"
                    />
                    <span className="secondary-color">Remember me</span>
                  </div>
                  <div className="my-4">
                    <button
                      type="button"
                      className="bg-white px-5 py-2 w-100 secondary-btn"
                      onClick={onSignIn}
                    >
                      Sign in
                    </button>
                  </div>
                  <div className="my-2 text-center">
                    <span className="secondary-color">
                      Forgot your password?{" "}
                    </span>
                    <span className="text-white text-decoration-underline">
                      Reset Password
                    </span>
                  </div>
                  <div className="mt-2 mb-5 text-center">
                    <span className="secondary-color">
                      Don't have an account?{" "}
                    </span>
                    <span
                      className="text-white text-decoration-underline cursor-pointer"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigation("/programs")}
                    >
                      Explore domains
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0 d-none d-md-block pt-4">
              <div className="d-flex justify-content-end">
                <img src={SignInImage} className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 border-top border-bottom border-light mt-3 pt-4 text-white">
          <Footer />
        </div>
      </div>
    </div>
  );
};
