import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { toast } from "react-toastify";
import { AdminService } from "../../../app/service/admin.service";
import { UserService } from "../../../app/service/user.service";
import moment from "moment";

const UserProjects = () => {
  const [file, setFile] = useState<any>(null);
  const [cgpa, setCgpa] = useState("");
  const [cgpaData, setCgpaData] = useState<any>([]);

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleCgpaChange = (event: any) => {
    setCgpa(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const loginData: any = sessionStorage.getItem("loginData");
      const data = JSON.parse(loginData);
      const formData = new FormData();
      formData.append("uploadCgpa", file);
      formData.append("expectingCgpa", cgpa);
      formData.append("emailAddress", data.email);
      const res = await UserService.createCgpa(formData);
      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success("successfully created");
        getCgpa();
        setFile("");
        setCgpa("");
      }
    } catch (error) {
      toast.error("An error occurred while creating the course");
      console.error("Error creating course:", error);
    }
  };

  useEffect(() => {
    getCgpa();
  }, []);

  const getCgpa = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole.email;
    const data = {
      email: email,
    };
    UserService.cgpaList(data)
      .then((res: any) => {
        if (res.status === "Success") {
          setCgpaData(res.data);
        } else {
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
      });
  };
  // const handleView = (item: any) => {
  //   const url = item.uploadCgpa;
  //   window.open(url, "_blank");
  // };
  const dateFormat = (dateStr: any) => {
    const date = moment(dateStr);
    const formattedDate = date.format("DD-MMM-YYYY");
    return formattedDate;
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg mt-5 px-5">
            <div className="row">
              <div className="col-md-4 mt-5">
                <h4>Upload your curriculum</h4>
                <input
                  type="file"
                  className="form-control-file mt-3"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                />
              </div>
              <div className="col-md-4 mt-5">
                <h4>Expecting CGPA</h4>
                <input
                  type="text"
                  className="form-control"
                  value={cgpa}
                  onChange={handleCgpaChange}
                />
                <button className="btn btn-success mt-3" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-5">
                {cgpaData?.length > 0 && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ color: "white" }} className="primary-bg">
                          Title
                        </th>
                        <th style={{ color: "white" }} className="primary-bg">
                          Description
                        </th>
                        <th style={{ color: "white" }} className="primary-bg">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cgpaData?.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{item.title}</td>
                            <td>
                            {item.description}
                            </td>
                            <td>{dateFormat(item.createdAt)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProjects;
