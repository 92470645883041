import DataScience from "./../src/assets/images/Data Science.png";
import AI from "./../src/assets/images/Artificial Intelligence.png";
import AutoCad from "./../src/assets/images/Auto Cad.png";
import React_16 from "./../src/assets/images/React.png";
import embs from "./../src/assets/images/IOT & EMBEDDED SYSTEMS.png";
import machineLearning from "./../src/assets/images/Machine Learning.png";
import vlsi from "./../src/assets/images/VLSI.png";
import startup from "./../src/assets/images/Startup and entreprenuership.png";
import cybersecurity from "./../src/assets/images/CYBER SECURITY.png";
import robotics from "./../src/assets/images/ROBOTICS USING PYTHON.png";
import cloudComputing from "./../src/assets/images/CLOUD COMPUTING.png";
import web from "./../src/assets/images/Web Development.png";

export const domains = [
  {
    image: DataScience,
    title: "Data Science",
    description:
      "Data science empowers engineering students to analyze complex data, driving innovation and informed solutions.",
    value: "DataScience",
    about:
      "Data Science Program provides essential skills in data manipulation, statistics, machine learning, and visualization. Starting with core concepts, participants learn Python/R and data manipulation. They progress to statistical analysis, then dive into machine learning basics. Modules focus on data wrangling techniques and practical projects. The program culminates in a capstone project where participants solve real-world data problems. Graduates leave with a strong understanding of data science principles and a portfolio demonstrating their abilities. Whether you're new to data science or a seasoned pro, our program prepares you for success in today's data-driven world.",
    participationCertification:
      "Upon participating in our training program, you'll receive a participation certificate acknowledging your dedication to advancing your skills in data science. This certificate highlights your commitment to learning and professional development, setting you apart as a motivated individual eager to excel in the field of data analytics. With this recognition, you'll demonstrate your proactive approach to acquiring new knowledge and enhancing your expertise. Join our program and receive your participation certificate as a testament to your commitment to personal and professional growth.",
    trainingCompletioncertification:
      "Upon finishing our training program, you'll receive a certification recognizing your expertise in data science. This credential signifies your proficiency in essential skills like data manipulation, statistical analysis, and machine learning. It validates your commitment to continuous learning and opens doors to new career opportunities in the rapidly growing field of data analytics. Backed by industry recognition and expert-designed curriculum, our certification is a valuable asset that enhances your credibility and marketability. Join our program and earn your badge of achievement in data science today.",
    internshipCompletioncertification:
      "Upon completing your internship with us, you'll earn a certification recognizing your valuable contributions and dedication during your time with our team. This internship certificate serves as a testament to your hands-on experience and practical skills gained in the field of data science. It validates your commitment to learning and professional growth, showcasing your readiness to embark on a successful career in the industry. With this certification, you'll demonstrate to future employers your capability to apply theoretical knowledge to real-world scenarios, setting you apart as a qualified and competent candidate. Join our internship program and earn your certification as a testament to your internship achievements and readiness for the workforce.",
    curriculum: [
      {
        category: "Foundations of Data Science",
        topics: [
          "Introduction to data science principles and methodologies.",
          "Basics of programming languages like Python or R.",
          "Data manipulation and visualization techniques.",
        ],
      },
      {
        category: "Statistical Analysis and Probability",
        topics: [
          "Fundamentals of statistics and probability theory.",
          "Descriptive statistics, probability distributions, and hypothesis testing.",
          "Regression analysis and correlation.",
        ],
      },
      {
        category: "Machine Learning Fundamentals",
        topics: [
          "Supervised and unsupervised learning algorithms.",
          "Model evaluation and validation techniques.",
          "Basic concepts of feature selection and engineering.",
        ],
      },
      {
        category: "Advanced Machine Learning",
        topics: [
          "Deep dive into neural network architectures.",
          "Convolutional and Recurrent Neural Networks for image and sequential data.",
          "Transfer learning and fine-tuning pre-trained models.",
        ],
      },
      {
        category: "Data Wrangling and Big Data Technologies",
        topics: [
          "Data cleaning, preprocessing, and integration techniques.",
          "Introduction to distributed computing frameworks like Hadoop and Spark.",
          "Working with large datasets and NoSQL databases.",
        ],
      },
      {
        category: "Applied Data Science",
        topics: [
          "Natural Language Processing (NLP) techniques.",
          "Text preprocessing, sentiment analysis, and topic modeling.",
          "Building interactive data visualizations and dashboards.",
        ],
      },
      {
        category: "Capstone Project and Ethics",
        topics: [
          "Application of data science techniques to solve real-world problems.",
          "Ethical considerations in data collection, usage, and algorithmic decision-making.",
          "Presentation of capstone projects and discussion of findings.",
        ],
      },
    ],
  },

  {
    image: AI,
    title: "Artificial Intelligence",
    description:
      "Elevate your engineering prowess with our AI program, designed to unleash innovation and shape the future!",
    value: "ArtificialIntelligence",
    about:
      "Our Advanced AI Engineering Program is tailored for engineering students looking to delve into the cutting-edge world of artificial intelligence. Through a dynamic curriculum, participants will master essential AI concepts and techniques, including machine learning, deep learning, and natural language processing. With a focus on hands-on learning and real-world applications, students will develop the skills needed to tackle complex engineering challenges and drive innovation in diverse fields. Whether you're interested in robotics, autonomous systems, or data-driven decision-making, this program will equip you with the tools and knowledge to excel in the rapidly evolving field of AI engineering. Join us and unlock your potential to shape the future with AI.",
    participationCertification:
      "Celebrate your active engagement and dedication to mastering AI with our Participation Certification. This prestigious credential acknowledges your commitment to learning and showcases your enthusiasm for advancing your skills in artificial intelligence. By completing our Advanced AI Engineering Program, you've demonstrated a proactive approach to shaping the future of technology. Display your certification proudly on your professional profiles and resumes to highlight your expertise and commitment to excellence in AI engineering. Congratulations on your achievement and best wishes for your continued success in the field of artificial intelligence!",
    trainingCompletioncertification:
      "Congratulations on successfully completing our rigorous Advanced AI Engineering Program! This certification recognizes your dedication and proficiency in mastering essential AI concepts and techniques. Showcase your expertise and readiness to tackle real-world challenges in artificial intelligence by displaying this prestigious certification on your professional profiles and resumes.",
    internshipCompletioncertification:
      "Embark on your AI journey with practical experience gained through our internship program. Congratulations on successfully completing your internship in our Advanced AI Engineering Program! This certification acknowledges your outstanding contributions and practical skills developed during your time with our team. Highlight your hands-on experience and readiness for the AI industry by displaying this certification proudly.",
    curriculum: [
      {
        category: "Introduction to Artificial Intelligence",
        topics: [
          "Overview of AI concepts, history, and applications.",
          "Introduction to machine learning, deep learning, and other AI subfields.",
          "Ethical considerations and societal impacts of AI.",
        ],
      },
      {
        category: "Machine Learning Fundamentals",
        topics: [
          "Basics of supervised and unsupervised learning algorithms.",
          "Linear regression, logistic regression, decision trees, and k-nearest neighbors.",
          "Model evaluation techniques and cross-validation.",
        ],
      },
      {
        category: "Deep Learning and Neural Networks",
        topics: [
          "Introduction to artificial neural networks (ANNs) and deep learning architectures.",
          "Convolutional Neural Networks (CNNs) for image recognition.",
          "Recurrent Neural Networks (RNNs) for sequential data analysis.",
        ],
      },
      {
        category: "Natural Language Processing (NLP)",
        topics: [
          "Text preprocessing techniques.",
          "Sentiment analysis, named entity recognition, and text classification.",
          "Sequence-to-sequence models and attention mechanisms.",
        ],
      },
      {
        category: "Reinforcement Learning",
        topics: [
          "Basics of reinforcement learning and Markov Decision Processes (MDPs).",
          "Q-learning, Deep Q-Networks (DQNs), and policy gradients.",
          "Applications in game playing, robotics, and autonomous systems.",
        ],
      },
      {
        category: "Advanced Topics in AI",
        topics: [
          "Generative Adversarial Networks (GANs) for image generation.",
          "Transfer learning and domain adaptation.",
          "Model deployment and serving in production environments.",
        ],
      },
      {
        category: "Capstone Project and Applications",
        topics: [
          "Hands-on project applying AI techniques to solve real-world problems.",
          "Project planning, data collection, model development, and evaluation.",
          "Presentation of project findings and insights.",
        ],
      },
    ],
  },
  {
    image: AutoCad,
    title: "Auto Cad",
    description:
      "Master the art of design with AutoCAD, the industry-leading software for precise 2D drafting and powerful 3D modeling.",
    value: "AutoCad",
    about:
      "AutoCAD Mastery Program empowers designers and engineers to excel in 2D drafting and 3D modeling. Through seven modules, participants progress from basic commands to advanced techniques, gaining hands-on experience through project-based learning. Upon completion, participants receive a certification recognizing their mastery, opening doors to new career opportunities in architecture, engineering, and construction. Join us and elevate your design skills with AutoCAD.",
    participationCertification:
      "Celebrate your achievement in completing our AutoCAD Mastery Program with our Participation Certification. This certificate recognizes your active engagement and dedication throughout the program, showcasing your commitment to mastering essential design skills. Display your certification proudly on your professional profiles and resumes, signaling your readiness to excel in the fields of design and engineering. Congratulations on your accomplishment and best wishes for your continued success!",
    trainingCompletioncertification:
      "This certification recognizes your dedication and proficiency in mastering essential design skills. Display your certification proudly on your professional profiles and resumes, showcasing your expertise and readiness to tackle design challenges in various industries.",
    internshipCompletioncertification:
      "Embark on your design journey with practical experience gained through our internship program. Congratulations on completing your internship in our AutoCAD Mastery Program! This certification acknowledges your outstanding contributions and practical skills developed during your time with our team. Highlight your hands-on experience and readiness for the design industry by displaying this certification proudly.",
    curriculum: [
      {
        category: "Introduction to AutoCAD",
        topics: [
          "Overview of AutoCAD software and its applications in engineering and design.",
          "User interface familiarization, basic navigation, and workspace customization.",
          "Drawing setup, units, and scale configuration.	",
        ],
      },
      {
        category: "2D Drawing Fundamentals",
        topics: [
          "Basic drawing commands and tools for creating geometric shapes, lines, and polylines.",
          "Editing commands for modifying and manipulating objects, such as move, copy, rotate, and scale.",
          "Layers management for organizing and controlling the visibility of drawing elements.",
        ],
      },
      {
        category: "Precision Drafting Techniques",
        topics: [
          "Advanced drawing tools for creating accurate and precise geometry, including construction lines, arcs, and circles.",
          "Dimensioning and annotation tools for adding measurements, text, and labels to drawings.",
          "Object snapping and tracking techniques for aligning and referencing drawing elements.",
        ],
      },
      {
        category: "Modeling Basics",
        topics: [
          "Introduction to 3D modeling concepts and workspace navigation.",
          "Creating and editing 3D objects using extrude, revolve, and sweep commands",
          "Viewing and visualizing 3D models from different perspectives and viewpoints.",
        ],
      },
      {
        category: "Advanced 3D Modeling",
        topics: [
          "Complex 3D modeling techniques such as lofting, slicing, and Boolean operations.",
          "Surface modeling tools for creating smooth and curved surfaces.",
          "Parametric modeling and constraints for controlling the relationships between objects.",
        ],
      },
      {
        category: "Rendering and Visualization",
        topics: [
          "Applying materials, textures, and lighting effects to enhance the appearance of 3D models.",
          "Using rendering presets and settings to generate photorealistic renderings.",
          "Creating walkthrough animations and presentations to showcase designs.",
        ],
      },
      {
        category: "Project-Based Learning and Customization",
        topics: [
          "Applying AutoCAD skills to real-world design projects, such as architectural floor plans or mechanical assemblies.",
          "Customizing AutoCAD environment through the creation of custom commands, tool palettes, and templates.",
          "Best practices for file management, collaboration, and sharing drawings with others.",
        ],
      },
    ],
  },
  {
    image: machineLearning,
    title: "Machine Learning",
    description:
      "Dive into the world of machine learning, where data-driven algorithms unlock insights and drive innovation.",
    value: "MachineLearning",
    about:
      "Embark on a transformative journey in our 7-module machine learning program. From fundamental concepts to advanced techniques, delve into predictive modeling and real-world applications. Gain hands-on experience through practical projects and unlock the power of innovation. Join us today to master machine learning and propel your career forward in this dynamic field.",
    participationCertification:
      "Recognize your active engagement and dedication in mastering machine learning concepts with our Participation Certification, validating your commitment to continuous learning and growth in this evolving domain.",
    trainingCompletioncertification:
      "Demonstrate your expertise and proficiency in machine learning techniques with our Training Completion Certification, showcasing your readiness to apply advanced algorithms and methodologies to solve real-world problems.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the machine learning domain with our Internship Completion Certification, affirming your ability to leverage data-driven insights and drive innovation in diverse applications.",
    curriculum: [
      {
        category: "Introduction to Machine Learning",
        topics: [
          "Overview of machine learning concepts, types of learning, and applications.",
          "Basic principles of supervised, unsupervised, and reinforcement learning.",
          "Introduction to Python programming language and libraries like NumPy and Pandas.",
        ],
      },
      {
        category: "Data Preprocessing and Exploratory Data Analysis",
        topics: [
          "Data cleaning, handling missing values, and outlier detection.",
          "Feature scaling, normalization, and transformation.",
          "Exploratory data analysis techniques to understand data distributions and relationships.",
        ],
      },
      {
        category: "Supervised Learning Algorithms",
        topics: [
          "Linear regression for predicting continuous outcomes.",
          "Logistic regression for binary classification problems.",
          "Decision trees, random forests, and ensemble methods for both regression and classification tasks.",
        ],
      },
      {
        category: "Unsupervised Learning Algorithms",
        topics: [
          "K-means clustering for grouping similar data points.",
          "Hierarchical clustering for creating clusters based on data hierarchy.",
          "Dimensionality reduction techniques like Principal Component Analysis (PCA) and t-distributed Stochastic Neighbor Embedding (t-SNE).",
        ],
      },
      {
        category: "Model Evaluation and Selection",
        topics: [
          "Evaluation metrics for regression and classification models, including accuracy, precision, recall, F1-score, -and mean squared error.",
          "Cross-validation techniques to assess model performance on unseen data.",
          "Hyperparameter tuning and model selection using techniques like grid search and randomized search.",
        ],
      },
      {
        category: "Advanced Topics in Machine Learning",
        topics: [
          "Support Vector Machines (SVM) for both classification and regression tasks.",
          "Neural networks and deep learning architectures such as Convolutional Neural Networks (CNNs) and Recurrent Neural Networks (RNNs).",
          "Transfer learning and fine-tuning pre-trained models for specific tasks.",
        ],
      },
      {
        category: "Applied Machine Learning and Capstone Project",
        topics: [
          "Real-world applications of machine learning in various domains such as healthcare, finance, and e-commerce.",
          "Hands-on projects where participants apply machine learning techniques to solve practical problems.",
          "Presentation and discussion of capstone projects, showcasing participants' mastery of machine learning concepts and skills.",
        ],
      },
    ],
  },
  {
    image: embs,
    title: "IOT & EMBEDDED SYSTEMS",
    description:
      "Explore the intersection of IoT and Embedded Systems, where smart devices connect and innovate to shape the future.",
    value: "EMBEDDEDSYSTEMS",
    about:
      "IoT & Embedded Systems Program is designed to equip participants with the knowledge and skills to navigate the convergence of hardware and software in the digital age. Through a comprehensive curriculum, participants will delve into the intricacies of embedded systems design, programming, and integration with IoT technologies. From microcontroller fundamentals to wireless communication protocols, this program provides a holistic understanding of the interconnected world of smart devices. With hands-on projects and real-world applications, participants will gain practical experience to tackle challenges in diverse industries. Join us and unlock the potential of IoT and Embedded Systems to drive innovation and shape the future.",
    participationCertification:
      "Celebrate your active engagement in our IoT & Embedded Systems Program with our Participation Certification. This prestigious certificate acknowledges your dedication and commitment to mastering essential concepts in the field. Display it proudly on your professional profiles and resumes, showcasing your proactive approach to learning and readiness to excel in the dynamic world of IoT and embedded systems. Join us and mark your journey towards becoming a proficient IoT and embedded systems professional.",
    trainingCompletioncertification:
      "Mark your proficiency and mastery of essential concepts with our Training Completion Certification. Showcase your readiness to tackle real-world challenges in the dynamic field of IoT and embedded systems.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions with our Internship Completion Certification. Display your hands-on expertise and readiness to make a meaningful impact in the IoT and embedded systems industry.",
    curriculum: [
      {
        category: "Introduction to IoT and Embedded Systems",
        topics: [
          "Overview of IoT and embedded systems, including their applications and significance in various industries.",
          "Introduction to microcontrollers, sensors, actuators, and communication protocols used in IoT devices.",
          "Basics of hardware and software development for embedded systems.",
        ],
      },
      {
        category: "Embedded Systems Programming",
        topics: [
          "Introduction to programming languages commonly used in embedded systems, such as C and C++.",
          "Hands-on exercises to develop skills in programming microcontrollers and interfacing with peripherals.",
          "Debugging techniques and best practices for embedded software development.",
        ],
      },
      {
        category: "IoT Protocols and Communication",
        topics: [
          "Overview of IoT communication protocols, including MQTT, CoAP, and HTTP.",
          "Introduction to wireless communication technologies such as Wi-Fi, Bluetooth, Zigbee, and LoRa.",
          "Hands-on exercises to implement communication protocols for IoT applications.",
        ],
      },
      {
        category: "Sensor Integration and Data Acquisition",
        topics: [
          "Understanding of various sensors used in IoT devices, including temperature, humidity, motion, and proximity sensors.",
          "Techniques for interfacing sensors with microcontrollers and acquiring sensor data.",
          "Data preprocessing and filtering techniques for sensor data.",
        ],
      },
      {
        category: "Embedded Systems Design and Development",
        topics: [
          "Design principles for embedded systems, including system architecture, hardware selection, and power management.",
          "Techniques for designing embedded firmware and software for specific applications.",
          "Introduction to real-time operating systems (RTOS) and their applications in embedded systems.",
        ],
      },
      {
        category: "IoT Applications and Prototyping",
        topics: [
          "Case studies and examples of IoT applications in smart homes, healthcare, agriculture, and industrial automation.",
          "Prototyping techniques using development boards like Arduino, Raspberry Pi, and ESP32.",
          "Integration of sensors, actuators, and communication modules to build IoT prototypes.",
        ],
      },
      {
        category: "Security and Deployment Considerations",
        topics: [
          "Understanding of security threats and vulnerabilities in IoT and embedded systems.",
          "Techniques for securing IoT devices and communication networks, including encryption, authentication, and access control.",
          "Deployment considerations for IoT solutions, including scalability, reliability, and interoperability.",
        ],
      },
    ],
  },
  {
    image: startup,
    title: "Startup and enterprenuership",
    description:
      "Empowering aspiring entrepreneurs with the knowledge and skills to launch and grow successful startups.",
    value: "Startupandenterprenuership",
    about:
      "Startup and Entrepreneurship Program is designed to equip aspiring entrepreneurs with the knowledge, skills, and mindset needed to navigate the challenges of building and scaling successful ventures. Through a comprehensive curriculum, participants will learn key concepts in business planning, market research, product development, marketing, financial management, and growth strategies. With hands-on projects, mentorship, and networking opportunities, participants will gain practical experience and insights from experienced entrepreneurs and industry experts. Join us and embark on a transformative journey to turn your startup ideas into reality.",
    participationCertification:
      "Celebrate your active engagement and dedication in our Startup and Entrepreneurship Program with our Participation Certification. This prestigious certificate recognizes your commitment to learning and growth, showcasing your proactive approach to mastering essential entrepreneurial skills. Display it proudly on your professional profiles, signaling your readiness to embark on your entrepreneurial journey.",
    trainingCompletioncertification:
      "Demonstrate your proficiency and mastery of entrepreneurial concepts with our Training Completion Certification. This certification validates your successful completion of our comprehensive program, showcasing your readiness to navigate the challenges of startup development and growth. Display it proudly on your professional profiles and resumes, highlighting your expertise and commitment to entrepreneurial success.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the startup ecosystem with our Internship Completion Certification. This certification recognizes your outstanding performance and valuable insights gained during your internship. Display it proudly on your professional profiles, signaling your readiness to make a meaningful impact in the entrepreneurial landscape.",
    curriculum: [
      {
        category: "Introduction to Entrepreneurship",
        topics: [
          "Overview of entrepreneurship, its importance, and the entrepreneurial mindset.",
          "Introduction to startup ecosystems, funding sources, and business models.",
          "Identifying opportunities and understanding market dynamics.",
        ],
      },
      {
        category: "Business Planning and Strategy",
        topics: [
          "Developing a business plan, including market analysis, competitive analysis, and financial projections.",
          "Strategic planning and goal setting for startup growth and sustainability.",
          "Lean startup methodology and agile development principles.",
        ],
      },
      {
        category: "Market Research and Validation",
        topics: [
          "Conducting market research to validate business ideas and understand customer needs.",
          "Techniques for customer discovery, user interviews, and prototype testing.",
          "Analyzing market trends, competition, and potential barriers to entry.",
        ],
      },
      {
        category: "Product Development and Innovation",
        topics: [
          "Product ideation and development processes, including design thinking and MVP (Minimum Viable Product) development.",
          "Iterative product testing and feedback gathering to refine and improve offerings.",
          "Innovation management and fostering a culture of creativity within the startup.",
        ],
      },
      {
        category: "Marketing and Sales Strategies",
        topics: [
          "Developing marketing strategies to reach target customers and create brand awareness.",
          "Digital marketing techniques such as social media marketing, content marketing, and SEO (Search Engine Optimization).",
          "Sales techniques, customer acquisition strategies, and building customer relationships.",
        ],
      },
      {
        category: "Financial Management and Funding",
        topics: [
          "Financial planning and budgeting for startups, including cash flow management and financial forecasting.",
          "Understanding different funding options, such as bootstrapping, angel investors, venture capital, and crowdfunding.",
          "Negotiation skills and pitching to potential investors.",
        ],
      },
      {
        category: "Scaling and Growth Strategies",
        topics: [
          "Strategies for scaling operations and expanding market reach.",
          "Managing growth challenges, including hiring talent, scaling infrastructure, and maintaining company culture.",
          "Exit strategies and succession planning for entrepreneurs looking to sell or transition their businesses.",
        ],
      },
    ],
  },
  {
    image: vlsi,
    title: "VLSI",
    description:
      "Dive into the world of VLSI design, where intricate chip architectures are crafted to power modern electronic devices.",
    value: "Vlsi",
    about:
      "VLSI(Very large scale integration) Design Program offers a comprehensive curriculum tailored to equip participants with the knowledge and skills needed to excel in the field of Very Large Scale Integration. From the fundamentals of digital logic design to advanced topics in physical design and verification, participants will delve deep into the intricacies of chip architecture and semiconductor technology. With hands-on projects, industry-relevant tools, and expert guidance, this program provides a transformative learning experience for aspiring VLSI engineers. Join us and embark on a journey to master the art and science of VLSI design, shaping the future of electronic systems",
    participationCertification:
      "Celebrate your active engagement and dedication in our VLSI Design Program with our Participation Certification. This prestigious certificate recognizes your commitment to learning and growth, showcasing your proactive approach to mastering essential VLSI concepts. Display it proudly on your professional profiles, signaling your readiness to contribute to the field of semiconductor technology.",
    trainingCompletioncertification:
      "Demonstrate your proficiency and mastery of VLSI design principles with our Training Completion Certification. This certification validates your successful completion of our comprehensive program, showcasing your readiness to tackle complex challenges in chip architecture and semiconductor technology. Display it proudly on your professional profiles and resumes, highlighting your expertise and commitment to excellence in VLSI design.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the VLSI industry with our Internship Completion Certification. This certification recognizes your outstanding performance and valuable insights gained during your internship. Display it proudly on your professional profiles, signaling your readiness to make a meaningful impact in the semiconductor industry.",
    curriculum: [
      {
        category: "Introduction to VLSI Design",
        topics: [
          "Overview of VLSI technology and its applications in electronic systems.",
          "Introduction to semiconductor fundamentals, integrated circuits, and chip design flow.",
          "Understanding VLSI design methodologies and CAD tools used in the industry.",
        ],
      },
      {
        category: "Digital Logic Design",
        topics: [
          "Basics of digital logic gates, Boolean algebra, and combinational logic.",
          "Sequential logic circuits, flip-flops, registers, and counters.",
          "Introduction to Verilog or VHDL for modeling and simulating digital circuits.",
        ],
      },
      {
        category: "CMOS Technology and Circuit Design",
        topics: [
          "Understanding CMOS (Complementary Metal-Oxide-Semiconductor) technology and its advantages in VLSI.",
          "CMOS transistor operation, fabrication process, and layout design rules.",
          "Circuit design techniques for CMOS logic gates, amplifiers, and memory cells.",
        ],
      },
      {
        category: "VLSI System Design",
        topics: [
          "System-level design considerations, including architectural specifications and trade-offs.",
          "Designing arithmetic units, data paths, and control units for VLSI systems.",
          "Concepts of pipelining, parallel processing, and memory hierarchy in VLSI architectures.",
        ],
      },
      {
        category: "Physical Design and Layout",
        topics: [
          "Introduction to physical design flow, including floor planning, placement, and routing.",
          "Layout design rules, design for manufacturability (DFM), and design rule checking (DRC).",
          "Timing closure techniques, power optimization, and signal integrity considerations.",
        ],
      },
      {
        category: "Testing and Verification",
        topics: [
          "Principles of VLSI testing, including fault modeling, test pattern generation, and fault simulation.",
          "Design for testability (DFT) techniques, scan chains, and built-in self-test (BIST) structures.",
          "Functional verification methodologies, simulation, and formal verification techniques.",
        ],
      },
      {
        category: "Advanced Topics in VLSI",
        topics: [
          "High-level synthesis (HLS) and RTL-to-GDS (Register-Transfer Level to Graphic Data System) design flow.",
          "Low-power design techniques, including power gating, voltage scaling, and clock gating.",
          "Emerging trends in VLSI technology, such as 3D integration, neuromorphic computing, and quantum computing.",
        ],
      },
    ],
  },

  {
    image: robotics,
    title: "ROBOTICS USING PYTHON",
    description:
      "Explore the intersection of robotics and Python programming to build intelligent and autonomous systems.",
    value: "ROBOTICSUSINGPYTHON",
    about:
      "Robotics Using Python Program is designed to empower participants with the knowledge and skills to create intelligent and autonomous systems. Through a blend of theoretical concepts and hands-on projects, participants will delve into the world of robotics, learning how to design, build, and program robots using the Python programming language. From understanding robot kinematics to implementing computer vision algorithms, this program offers a comprehensive learning experience for aspiring roboticists and programmers. Join us and unlock the potential of robotics with Python.",
    participationCertification:
      "Celebrate your active engagement and dedication in our Robotics Using Python Program with our Participation Certification. This prestigious certificate recognizes your commitment to learning and growth, showcasing your proactive approach to mastering essential concepts in robotics and Python programming. Display it proudly on your professional profiles, signaling your readiness to contribute to the field of intelligent systems.",
    trainingCompletioncertification:
      "Demonstrate your proficiency and mastery of robotics and Python programming principles with our Training Completion Certification. This certification validates your successful completion of our comprehensive program, showcasing your readiness to tackle complex challenges in designing and programming robots. Display it proudly on your professional profiles and resumes, highlighting your expertise and commitment to excellence in robotics.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the robotics industry with our Internship Completion Certification. This certification recognizes your outstanding performance and valuable insights gained during your internship. Display it proudly on your professional profiles, signaling your readiness to make a meaningful impact in the field of robotics.",
    curriculum: [
      {
        category: "Introduction to Robotics and Python",
        topics: [
          "Overview of robotics and its applications in various industries.",
          "Introduction to Python programming language and its importance in robotics.",
          "Basics of programming concepts such as variables, loops, conditionals, and functions in Python.",
        ],
      },
      {
        category: "Robotics Hardware and Electronics",
        topics: [
          "Introduction to robotics hardware components such as motors, sensors, and actuators.",
          "Understanding electronic circuits and interfacing with microcontrollers.",
          "Hands-on exercises to assemble and connect basic robotic components.",
        ],
      },
      {
        category: "Sensor Integration and Data Processing",
        topics: [
          "Introduction to sensors used in robotics such as proximity sensors, ultrasonic sensors, and gyroscopes.",
          "Techniques for interfacing sensors with microcontrollers and processing sensor data.",
          "Implementing sensor fusion techniques for improved accuracy and reliability.",
        ],
      },
      {
        category: "Robot Kinematics and Dynamics",
        topics: [
          "Fundamentals of robot kinematics, including forward and inverse kinematics.",
          "Understanding robot dynamics and control theory principles.",
          "Implementing motion planning algorithms for robot manipulation and navigation.",
        ],
      },
      {
        category: "Robot Vision and Image Processing",
        topics: [
          "Introduction to computer vision concepts and techniques.",
          "Image acquisition, processing, and analysis using Python libraries such as OpenCV.",
          "Implementing object detection, recognition, and tracking algorithms for robotic vision systems.",
        ],
      },
      {
        category: "Robot Control and Navigation",
        topics: [
          "Basics of robot control systems, including PID (Proportional-Integral-Derivative) control.",
          "Implementing motion control algorithms for robot navigation and manipulation.",
          "Integration of sensors and actuators for autonomous robot movement and interaction with the environment.",
        ],
      },
      {
        category: "Robotics Applications and Projects",
        topics: [
          "Real-world applications of robotics using Python, such as autonomous vehicles, robotic arms, and drones.",
          "Hands-on projects to design, build, and program robots for specific tasks.",
          "Presentation and discussion of capstone projects, showcasing participants' mastery of robotics concepts and skills.",
        ],
      },
    ],
  },
  {
    image: cybersecurity,
    title: "CYBER SECURITY",
    description:
      "Safeguarding digital assets through comprehensive cybersecurity strategies and practices.",
    value: "CyberSecurity",
    about:
      "Cybersecurity Program offers a comprehensive curriculum designed to equip participants with the knowledge and skills needed to protect digital assets from cyber threats. From understanding common attack vectors to implementing robust security measures, participants will delve into the intricacies of cybersecurity through theoretical concepts and practical exercises. With hands-on projects and real-world case studies, this program provides a transformative learning experience for aspiring cybersecurity professionals. Join us and become a guardian of the digital world.",
    trainingCompletioncertification:
      "Demonstrate your proficiency and mastery of cybersecurity principles with our Training Completion Certification. This certification validates your successful completion of our comprehensive program, showcasing your readiness to tackle complex challenges in protecting digital assets from cyber threats. Display it proudly on your professional profiles and resumes, highlighting your expertise and commitment to excellence in cybersecurity.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the cybersecurity industry with our Internship Completion Certification. This certification recognizes your outstanding performance and valuable insights gained during your internship. Display it proudly on your professional profiles, signaling your readiness to make a meaningful impact in the field of cybersecurity.",
    curriculum: [
      {
        category: "Introduction to Cybersecurity",
        topics: [
          "Overview of cybersecurity concepts, threats, and challenges.",
          "Understanding the importance of cybersecurity in protecting digital assets.",
          "Introduction to key cybersecurity principles and best practices.",
        ],
      },
      {
        category: "Network Security",
        topics: [
          "Fundamentals of network security, including protocols, encryption, and firewalls.",
          "Understanding network vulnerabilities and attack vectors.",
          "Techniques for securing network infrastructure and detecting intrusions.",
        ],
      },
      {
        category: "Operating System Security",
        topics: [
          "Secure configuration of operating systems (Windows, Linux, etc.).",
          "Hardening techniques to minimize vulnerabilities and strengthen security.",
          "Implementing access controls, user authentication, and privilege management.",
        ],
      },
      {
        category: "Web Security",
        topics: [
          "Common web security threats such as cross-site scripting (XSS) and SQL injection.",
          "Securing web applications and servers from attacks.",
          "Techniques for secure coding, input validation, and output encoding.",
        ],
      },
      {
        category: "Data Protection and Encryption",
        topics: [
          "Understanding data protection principles and regulations (GDPR, HIPAA, etc.).",
          "Encryption algorithms and protocols for data confidentiality.",
          "Implementing encryption in transit and at rest to protect sensitive information.",
        ],
      },
      {
        category: "Incident Response and Forensics",
        topics: [
          "Developing incident response plans and procedures.",
          "Investigating security incidents and conducting digital forensics.",
          "Post-incident analysis and remediation to prevent future incidents.",
        ],
      },
      {
        category: "Cybersecurity Governance and Compliance",
        topics: [
          "Overview of cybersecurity governance frameworks (ISO 27001, NIST, etc.).",
          "Compliance requirements and regulations relevant to cybersecurity.",
          "Risk management strategies and frameworks for assessing and mitigating cybersecurity risks.",
        ],
      },
    ],
  },
  {
    image: cloudComputing,
    title: "CLOUD COMPUTING",
    description:
      "Unlock the power of flexible, scalable computing with our Cloud Computing program.",
    value: "CloudComputing",
    about:
      "Cloud Computing Program offers a comprehensive curriculum designed to equip participants with the knowledge and skills needed to leverage the capabilities of cloud technology effectively. From understanding core concepts and service models to mastering advanced cloud deployment strategies, participants will embark on a transformative journey into the world of cloud computing. With hands-on labs, real-world case studies, and expert guidance, this program provides the essential foundation for building scalable, resilient, and cost-effective cloud-based solutions. Join us and revolutionize the way you deploy and manage IT infrastructure with cloud computing.",
    participationCertification:
      "Celebrate your active engagement and dedication in our Cloud Computing Program with our Participation Certification. This esteemed certificate recognizes your commitment to learning and growth in the field of cloud technology, showcasing your proactive approach to mastering essential concepts and practices. Display it proudly on your professional profiles, signaling your readiness to contribute to the transformation of IT infrastructure with cloud computing.",
    trainingCompletioncertification:
      "Demonstrate your proficiency and mastery of cloud computing principles with our Training Completion Certification. This certification validates your successful completion of our comprehensive program, showcasing your readiness to tackle complex challenges in deploying and managing cloud-based solutions. Display it proudly on your professional profiles and resumes, highlighting your expertise and commitment to excellence in cloud computing.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the cloud computing industry with our Internship Completion Certification. This certification recognizes your outstanding performance and valuable insights gained during your internship. Display it proudly on your professional profiles, signaling your readiness to make a meaningful impact in the field of cloud technology.",
    curriculum: [
      {
        category: "Introduction to Cloud Computing",
        topics: [
          "Overview of cloud computing concepts, including service models (IaaS, PaaS, SaaS) and deployment models (public, private, hybrid).",
          "Understanding the benefits, challenges, and use cases of cloud computing.",
          "Introduction to major cloud service providers (AWS, Azure, Google Cloud).",
        ],
      },
      {
        category: "Cloud Infrastructure and Virtualization",
        topics: [
          "Fundamentals of cloud infrastructure components such as virtual machines, containers, and storage.",
          "Virtualization technologies and hypervisors for resource abstraction and management.",
          "Hands-on exercises to provision and manage virtualized resources in a cloud environment.",
        ],
      },
      {
        category: "Cloud Networking and Security",
        topics: [
          "Basics of cloud networking, including virtual networks, subnets, and routing.",
          "Implementing network security measures such as firewalls, access control lists (ACLs), and VPNs.",
          "Best practices for securing data in transit and at rest in the cloud.",
        ],
      },
      {
        category: "Cloud Storage and Database Services",
        topics: [
          "Overview of cloud storage services such as object storage, block storage, and file storage.",
          "Database services offered by cloud providers, including relational databases, NoSQL databases, and data warehousing.",
          "Data management and migration strategies for cloud-based storage and databases.",
        ],
      },
      {
        category: "Cloud Computing Platforms and Services",
        topics: [
          "Introduction to cloud computing platforms and services, including compute, storage, database, networking, and analytics.",
          "Hands-on experience with cloud service offerings such as EC2, S3, RDS, and Lambda.",
          "Understanding pricing models and cost optimization strategies for cloud services.",
        ],
      },
      {
        category: "Cloud Deployment and Management",
        topics: [
          "Strategies for deploying applications and workloads in the cloud, including automation and orchestration tools (e.g., Terraform, Kubernetes).",
          "Monitoring and managing cloud resources for performance, scalability, and availability.",
          "Disaster recovery and backup solutions for cloud-based environments.",
        ],
      },
      {
        category: "Advanced Topics in Cloud Computing",
        topics: [
          "Emerging trends and technologies in cloud computing, such as serverless computing, edge computing, and multi-cloud architecture.",
          "Compliance, governance, and regulatory considerations for cloud deployments.",
          "Capstone projects and case studies exploring real-world applications and challenges in cloud computing.",
        ],
      },
    ],
  },
  {
    image: web,
    title: "Web development",
    description:
      "Master the art of creating dynamic and responsive websites with our comprehensive web development program.",
    value: "Web development",
    about:
      "Web Development Program offers a comprehensive curriculum designed to equip participants with the skills and knowledge needed to thrive in the dynamic world of web development. From mastering the fundamentals of HTML, CSS, and JavaScript to building full-stack web applications, participants will embark on a transformative journey into the realm of modern web development. With hands-on projects, real-world case studies, and expert mentorship, this program provides the essential foundation for launching a successful career in web development. Join us and unlock the potential to create impactful and engaging web experiences.",
    participationCertification:
      "Celebrate your active engagement and dedication in our Web Development Program with our Participation Certification. This prestigious certificate recognizes your commitment to learning and growth in the field of web development, showcasing your proactive approach to mastering essential concepts and practices. Display it proudly on your professional profiles, signaling your readiness to contribute to the creation of dynamic and responsive websites",
    trainingCompletioncertification:
      "Demonstrate your proficiency and mastery of web development principles with our Training Completion Certification. This certification validates your successful completion of our comprehensive program, showcasing your readiness to tackle complex challenges in building modern web applications. Display it proudly on your professional profiles and resumes, highlighting your expertise and commitment to excellence in web development.",
    internshipCompletioncertification:
      "Highlight your practical experience and contributions in the web development industry with our Internship Completion Certification. This certification recognizes your outstanding performance and valuable insights gained during your internship. Display it proudly on your professional profiles, signaling your readiness to make a meaningful impact in the field of web development.",
    curriculum: [
      {
        category: "Introduction to Web Development",
        topics: [
          "Overview of web development concepts, including front-end, back-end, and full-stack development.",
          "Introduction to HTML, CSS, and JavaScript, the building blocks of web development.",
          "Understanding the role of web browsers and web servers in delivering web content.",
        ],
      },
      {
        category: "Front-End Development",
        topics: [
          "Deep dive into HTML for structuring web pages and content.",
          "Styling web pages using CSS for layout, colors, fonts, and responsiveness.",
          "Enhancing user interactivity and experience with JavaScript for client-side scripting and DOM manipulation.",
        ],
      },
      {
        category: "Responsive Web Design",
        topics: [
          "Principles of responsive web design for creating websites that adapt to different screen sizes and devices.",
          "Techniques for implementing media queries, flexible grids, and responsive images.",
          "Introduction to CSS frameworks like Bootstrap for rapid prototyping and development.",
        ],
      },
      {
        category: "Back-End Development",
        topics: [
          "Introduction to server-side programming languages such as Node.js, Python, or PHP.",
          "Setting up and configuring web servers (e.g., Apache, Nginx) and databases (e.g., MySQL, MongoDB).",
          "Building dynamic web applications with server-side frameworks like Express.js, Django, or Flask.",
        ],
      },
      {
        category: "Database Management and Integration",
        topics: [
          "Basics of relational and non-relational databases and their role in web development.",
          "CRUD operations (Create, Read, Update, Delete) for interacting with databases.",
          "Techniques for integrating databases into web applications using ORM (Object-Relational Mapping) libraries or NoSQL query languages.",
        ],
      },
      {
        category: "Web Development Tools and Version Control",
        topics: [
          "Introduction to development tools such as text editors, IDEs, and browser developer tools.",
          "Version control systems (e.g., Git) for managing codebase changes and collaboration.",
          "Deployment strategies for deploying web applications to production servers or cloud platforms.",
        ],
      },
      {
        category: "Advanced Web Development Topics",
        topics: [
          "Exploring advanced topics such as authentication and authorization, API integration, and security best practices.",
          "Introduction to front-end frameworks/libraries (e.g., React.js, Vue.js) and back-end frameworks (e.g., Express.js, Django).",
          "Capstone project or portfolio development to showcase skills and expertise in web development.",
        ],
      },
    ],
  },
];
