import React, { useContext, useEffect, useState } from "react";
export const AppContext = React.createContext({});
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: any) => {
  let loaderElement: any;
  let count: number = 0;
  const showLoader = () => {
    if (!count) {
      loaderElement = document?.getElementById("loader-block");
      loaderElement.style.display = "block";
    }
    count++;
  };
  const hideLoader = () => {
    count--;
    if (!count) {
      setTimeout(() => {
        loaderElement = document?.getElementById("loader-block");
        loaderElement.style.display = "none";
      }, 0);
    }
  };

  return (
    <AppContext.Provider value={{ showLoader, hideLoader }}>
      {" "}
      {children}
    </AppContext.Provider>
  );
};
