import React from 'react';
import Clock from './../../assets/images/clock.png';
import dateImg from './../../assets/images/Vector.png';
import insImg from './../../assets/images/pace.png';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making API calls

export const MasteringReact = (props:any) => {
    const navigate = useNavigate();

    const dateFormat = (dateStr:any) => {
        const date = moment(dateStr);
        const formattedDate = date.format('Do MMMM, YYYY');
        return formattedDate;
    };
    const handleProgram = () => {
        window.open(
          "https://eduexpose.mojo.page/check",
          "_blank"
        );
      };

    return (
        <div className='px-2 mx-md-5'>
            <div className="row">
                <div className='col-md-12'>
                    <div className='fs-1 fw-bold pt-5'>Mastering <span className='primary-color'>{props?.data?.courseName}</span></div>
                    <div className='secondary-color my-3'>
                        <p className="card-text secondary-color text-center">{props?.data?.description}</p>
                    </div>
                    <div className='m-3 mb-4 my-md-5'>
                        <button className='bg-white px-3 py-2 secondary-btn' onClick={handleProgram}>Start Program</button></div>
                    <img src={props?.data?.image} className='h-auto h-md-50 mb-5 acquire-skills-video rounded' alt="Course" />
                    <div className='row d-md-flex justify-content-between mx-lg-5 mb-3'>
                        <div className='col-6 col-md-3 my-md-0 py-2 ps-0 with-border-right'>
                            <div className='d-flex'>
                                <div><img className='img-fluid me-3' src={dateImg} alt="Start Date" /></div>
                                <div className="text-start ps-0 ps-md-2">
                                    <div>Start Date</div>
                                    <div className='py-1 secondary-color fs-14'><span>{dateFormat(props?.data?.startDate)}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-3 my-md-0 py-2 pe-0 with-border-right'>
                            <div className='d-flex'>
                                <div><img className='img-fluid me-3' src={insImg} alt="Course Pace" /></div>
                                <div className='text-start ps-0 ps-md-2'>
                                    <div>Course Pace</div>
                                    <div className='py-1 secondary-color fs-14'><span>Instructor Paced</span></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6 col-md-3 my-md-0 py-2 ps-0 ps-md-2'>
                            <div className='d-flex'>
                                <div><img className='img-fluid me-3' src={Clock} alt="Program Fee" /></div>
                                <div className='text-start ps-0 ps-md-2'>
                                    <div>Program Fee</div>
                                    <div className='py-1 secondary-color fs-14'><span>{props?.data?.amount}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
