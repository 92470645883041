import React, { useState } from "react";

interface CourseData {
  image: string;
  title: string;
  description: string;
  curriculum: {
    category: string;
    topics: string[];
  }[];
}

interface CmsCourseDataProps {
  courseData: CourseData;
  lmsData: any[]; // Ensure you type this properly according to your lmsData structure
}

const CmsCourseData = (props: any) => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const playVideo = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-9">
          <div className="course-info">
            {/* Conditionally hide the image and title when a video is selected */}
            {!selectedVideo && (
              <>
                <img
                  src={props.courseData?.image}
                  alt={props.courseData?.title}
                  className="mb-3 img-fluid border-top-0 border"
                />
                <h4>{props?.courseData?.courseName}</h4>
              </>
            )}
            <p>{props?.courseData?.description}</p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="curriculum">
            <h5>Course Curriculum</h5>
            {props?.lmsData?.map((item: any, index: any) => (
              <div key={index}>
                <p>{item.title}</p>
                <button
                  onClick={() => playVideo(item.uploadVideo)}
                  className="btn btn-link text-left p-0 play-button"
                >
                  <span role="img" aria-label="play">▶️</span> {item.title}
                </button>
              </div>
            ))}
          </div>
        </div>
        {selectedVideo && (
          <div className="col-md-9">
            <div className="video-player mt-3">
              <video width="100%" controls>
                <source src={selectedVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CmsCourseData;
