import React,{useEffect} from "react";
import DataScience from "./../../assets/images/data-science.png";
import AI from "./../../assets/images/genearative-ai.png";
import React_16 from "./../../assets/images/React.png";
import JoinCommunity from "./../../assets/images/join-community.png";
import Google from "./../../assets/images/acquire-skills/google.png";
import { TrendingDomainCards } from "../trending-domain-cards";
import tcs from "./../../assets/images/companies/tcs.png";
import hcl from "./../../assets/images/companies/hcl.png";
import cts from "./../../assets/images/companies/congizant.png";
import techmahendra from "./../../assets/images/companies/techmahendra.png";
import microsoft from "./../../assets/images/companies/microsoft.png";
import infoys from "./../../assets/images/companies/infoys.png";
import HomeVideo from "./../../assets/images/home_video.png";
import mphasis from "./../../assets/images/companies/mphasis.png";
import { useNavigate } from "react-router-dom";

export const TrendingDomains = () => {
  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const handleClick = () => {
    navigate("/alldomains");
  };
  return (
    <div className="px-0 px-lg-2 mx-md-5">
      <div className="d-md-flex justify-content-between mb-4 mb-sm-5">
        <div className="text-start">
          <div className="medium-header">
            Trending <span className="primary-color">domains</span>
          </div>
        </div>
        <div className="my-3 my-sm-4 mt-md-2 text-start me-auto me-md-0">
          <button
            className="bg-transparent text-white border border-white px-4 py-1 primary-btn"
            onClick={handleClick}
          >
            All Domains
          </button>
        </div>
      </div>
      <TrendingDomainCards />
      {/* join our community starts  */}
      {/* <div className='row mb-5'>
                <div className='col-md-6 text-start'>
                    <div className='fs-2 fw-bold'>Join our <span className='primary-color'>community</span> of<br /> learners</div>
                    <div className='secondary-color'>Joined in the community is very useful to the learner because they gain lot of knowledge</div>
                </div>
                <div className='col-md-6 my-4 my-md-0 d-md-flex justify-content-end'>
                    <img src={JoinCommunity} className='w-100 w-md-75 px-3 px-ms-0' />
                </div>
            </div> */}
      {/* join our community ends  */}
      {/* <div className='mx-3 mx-md-0'> */}
      {/* <div className='text-start fs-5'>Trusted by</div> */}
      {/* <div className='text-start fs-5 pt-3 pb-3'>Our Alumini Partners works at</div> */}
      {/* <div className='row d-md-flex justify-content-between mx-0 mt-4 mb-5'>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                    <div className='col-5 col-md-2 border border-white my-2 my-md-3 my-md-0 px-1 px-md-2 py-2 py-md-3'>
                        <div className='px-4'><span className='mx-2'><img src={Google} /></span><span>Google</span></div>
                    </div>
                </div> */}
      {/* <div className='row d-md-flex justify-content-between mx-2 mx-md-5 mb-5 mt-5'>
                    <div className='col-5 col-md-2'>
                        <img src={tcs} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={hcl} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={microsoft} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={infoys} alt="" />
                    </div>
                    <div className='col-5 col-md-2'>
                        <img src={techmahendra} alt="" />
                    </div>


                </div> */}
      {/* </div> */}
    </div>
  );
};
