import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { UserService } from "../../../app/service/user.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

const UserProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Personal");
  const navigation = useNavigate();
  const [userInfo, setUserInfo] = useState<any>({});

  // State for each section
  const [personalInfo, setPersonalInfo] = useState<any>({});
  const [educationalInfo, setEducationalInfo] = useState<any>({});
  const [contactInfo, setContactInfo] = useState<any>({});

  useEffect(() => {
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    getUserInfo(data?.id);
  }, []);

  const getUserInfo = async (id: any) => {
    let payload = { userId: id };
    try {
        const res = await UserService.userInfo(payload);
        let response = res?.data;
        if (res.status === "Success") {
            setUserInfo(response);

            // Format the date before setting it
            const personalInfo = response.personalInfo || {};
            if (personalInfo.dob) {
                personalInfo.dob = moment(personalInfo.dob).format('YYYY-MM-DD'); // or any desired format
            }

            setPersonalInfo(personalInfo);
            setEducationalInfo(response.educationalInfo || {});
            setContactInfo(response.contactInfo || {});
        }
    } catch (err) {
        console.log(err);
    }
};

  const handleSave = async () => {
    try {
      let payload;
      switch (activeLink) {
        case "Personal":
          payload = { personalInfo, userId: userInfo._id };
          await UserService.updatePersonalInfo(payload);
          break;
        case "Educational":
          payload = { educationalInfo, userId: userInfo._id };
          await UserService.updatePersonalInfo(payload);
          break;
        case "Contact":
          payload = { contactInfo, userId: userInfo._id };
          await UserService.updatePersonalInfo(payload);
          break;
      }
      toast.success("Profile updated successfully");
      const loginData: any = sessionStorage.getItem("loginData");
      const data = JSON.parse(loginData);
      getUserInfo(data?.id);
      setIsOpen(false);
    } catch (err) {
      console.error("Error in handleSave:", err);
      toast.error("Failed to update profile");
    }
  };


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    switch (activeLink) {
      case "Personal":
        setPersonalInfo({ ...personalInfo, [name]: value });
        break;
      case "Educational":
        setEducationalInfo({ ...educationalInfo, [name]: value });
        break;
      case "Contact":
        setContactInfo({ ...contactInfo, [name]: value });
        break;
    }
  };
  const handleClick = (link: string) => {
    setActiveLink(link);
  };
  const handleClickProfile = async () => {
    Swal.fire({
      title: "Upload Profile Picture",
      html: '<input type="file" id="file-upload" class="swal2-file" accept="image/*" />',
      showCancelButton: true,
      confirmButtonText: "Upload",
      preConfirm: () => {
        const fileInput = document.getElementById("file-upload") as HTMLInputElement;
        if (!fileInput?.files?.length) {
          Swal.showValidationMessage("Please select an image to upload");
          return null;
        }
        return fileInput.files[0];
      },
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        const file = result.value;
        const loginData: any = sessionStorage.getItem("loginData");
        const data = JSON.parse(loginData);
        const formData = new FormData();
        formData.append("profileUrl", file);
        formData.append("userId", data.id);
        const res = await UserService.uploadProfile(formData);
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          getUserInfo(data?.id);
          toast.success("Profile picture uploaded successfully");
        }
      }
    });
  };
  
  

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-5 py-5">
            <div className="row">
              <div className="col-md-3 text-center">
                <h4>
                  <b>My Profile</b>
                </h4>
              </div>
              <div className="col-md-9 text-end">
                {isOpen ? (
                  <div>
                    <button className="btn btn-primary" onClick={handleSave}>
                      Save
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    className="btn btn-danger"
                    onClick={() => setIsOpen(true)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-3 shadow rounded-3 mt-4 mb-4 pb-4">
                  <div className="ourcoursescard py-3">
                    <div className="profile-layout">
                      <img
                        src={userInfo?.profileUrl}
                        className="rounded mt-3 text-center pb-3 d-flex justify-content-center profile-img"
                        style={{ margin: "auto" }}
                      />
                      <span
                        className="testing-camera rounded text-center d-flex justify-content-center"
                        onClick={handleClickProfile}
                      >
                        <i
                          className="bi bi-camera ms-2"
                          style={{ fontSize: "40px" }}
                        ></i>
                      </span>
                    </div>
                    <div className="d-flex border-bottom justify-content-center">
                      <span className="pt-2">Name: </span>
                      <h6 className="mt-1 me-2 pb-2 pt-2">
                        {userInfo?.firstName || "-"}
                      </h6>
                    </div>
                    <div className="d-flex border-bottom justify-content-center">
                      <span className="pt-2">Phone:</span>
                      <h6 className="mt-1 me-2 pb-2 pt-2">
                        {userInfo?.phone || "-"}
                      </h6>
                    </div>
                    <div className="d-flex border-bottom justify-content-center">
                      <span className="pt-2">Address:</span>
                      <h6 className="mt-1 me-2 pb-2 pt-2">{contactInfo?.city || "-"}</h6>
                    </div>
                    <div className="d-flex justify-content-center">
                      <span className="pt-2">Pincode:</span>
                      <h6 className="mt-1 me-2 pb-2 pt-2">{contactInfo?.pincode || "-"}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-8 shadow rounded-3 mt-4 pb-4">
                  <ul className="nav px-5 py-5">
                    <li className="nav-item">
                      <a
                        className={`nav-link nav_user ${
                          activeLink === "Personal" ? "active_one" : ""
                        }`}
                        href="#"
                        onClick={() => handleClick("Personal")}
                      >
                        Personal
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link nav_user ${
                          activeLink === "Educational" ? "active_one" : ""
                        }`}
                        href="#"
                        onClick={() => handleClick("Educational")}
                      >
                        Educational
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link nav_user ${
                          activeLink === "Contact" ? "active_one" : ""
                        }`}
                        href="#"
                        onClick={() => handleClick("Contact")}
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                  {activeLink === "Personal" && (
                    <div className="row pb-4">
                      <div className="col-md-6">
                        <label>Student Name*</label>
                        <br />
                        <input
                          type="text"
                          name="studentName"
                          value={personalInfo.studentName || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                        <label>Date of Birth</label>
                        <br />
                        <input
                          type="date"
                          name="dob"
                          value={personalInfo.dob || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                      </div>
                      <div className="col-md-6">
                        <label>User Name*</label>
                        <br />
                        <input
                          type="text"
                          name="userName"
                          value={personalInfo.userName || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                        <label>Gender</label>
                        <br />
                        <select
                          name="gender"
                          value={personalInfo.gender || ""}
                          onChange={handleChange}
                          className="form-control"
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {activeLink === "Educational" && (
                    <div className="row pb-4">
                      <div className="col-md-6">
                        <label>Course*</label>
                        <br />
                        <input
                          type="text"
                          name="course"
                          value={educationalInfo.course || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                        <label>University</label>
                        <br />
                        <input
                          type="text"
                          name="university"
                          value={educationalInfo.university || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                      </div>
                      <div className="col-md-6">
                        <label>Year of Passing*</label>
                        <br />
                        <input
                          type="text"
                          name="yearOfPassing"
                          value={educationalInfo.yearOfPassing || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                        <label>Grade</label>
                        <br />
                        <input
                          type="text"
                          name="grade"
                          value={educationalInfo.grade || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}
                  {activeLink === "Contact" && (
                    <div className="row pb-4">
                      <div className="col-md-6">
                        <label>Address*</label>
                        <br />
                        <input
                          type="text"
                          name="address"
                          value={contactInfo.address || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                        <label>City</label>
                        <br />
                        <input
                          type="text"
                          name="city"
                          value={contactInfo.city || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                      </div>
                      <div className="col-md-6">
                        <label>State*</label>
                        <br />
                        <input
                          type="text"
                          name="state"
                          value={contactInfo.state || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        <br />
                        <label>Pincode</label>
                        <br />
                        <input
                          type="text"
                          name="pincode"
                          value={contactInfo.pincode || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
