import { DataTableCol } from "../../../components/tables/types";

export const CareerGuidancesDataGridCols: DataTableCol[] = [
    {
        title: 'Email',
        control: 'emailAddress',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Select Topic',
        control: 'selectTopic',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Text Here',
        control: 'textHere',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        // isEdit: true,
        // isDelete: true,
        isAccess:true,
        canShowColumn: true
    },
];