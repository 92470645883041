import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./../../assets/images/Logo.png";
import Logo_two from './../../assets/images/Frame.png';
import ProfileImage from "./../../assets/images/dashboard/profile.png";
import Logout from "./../../assets/images/dashboard/logout.png";
import { ROLES } from "../../app/utility/app-codes";
import Swal from "sweetalert2";

export const Header = () => {
  const navigation = useNavigate();
  const userdata: any = sessionStorage.getItem("loginData");
  const userRole = JSON.parse(userdata);

  // const onClickLogout = () => {
  //   navigation("/");
  //   sessionStorage.clear();
  // };
  const onClickLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        // or localStorage.clear(); if you're using localStorage
        
        // Redirect to home or login page
        navigation('/');
      }
    });
  };
  const handleClickUser = () => {
    navigation("/user/profile");
  };

  return (
    <div className="text-white d-flex primary-bg justify-content-between py-3">
      <div>
        <a className="navbar-brand text-white fw-bold">
          <div>
            <img
              src={Logo_two}
              className=""
              style={{ height: "55px", width: "100%" }}
            />
          </div>
          {/* <div className="text-white text-start" style={{ fontSize: "12px" }}>
            Unleash your skills{" "}
          </div> */}
        </a>
      </div>
      <div className="d-flex justify-content-between">
        {ROLES.User === userRole?.role?.roleName ? (
          <>
            {/* <div className="text-white">My Plan</div> */}
            <div className="mx-3 cursor-pointer">
              <img
                src={userRole?.userProfile || ProfileImage}
                className="user-profile-image"
                onClick={() => handleClickUser()}
              />
            </div>
            <div>
              <button
                className="bg-transparent border-0 cursor-pointer"
                onClick={onClickLogout}
              >
                <img src={Logout} className="user-profile-image" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="text-white mx-3 cursor-pointer pt-1">
              <h4>
                {userRole?.role?.roleName?.charAt(0)?.toUpperCase() +
                  userRole?.role?.roleName?.slice(1)?.toLowerCase()}
              </h4>
            </div>
            <div>
              <button
                className="bg-transparent border-0 cursor-pointer"
                onClick={onClickLogout}
              >
                <img src={Logout} className="user-profile-image" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
