import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { courses } from "../../../config";

const CreateMeet = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    title: "",
    link: "",
    courseName:"",
    meetDate: null,
    startTime: "",
    endTime: "",
  });
  const [errors, setErrors] = useState<any>({});

  const timeOptions = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      meetDate: date,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      meetDate: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.title) {
      newErrors.title = "Title is required";
    }

    if (!formData.link) {
      newErrors.link = "Link is required";
    }

    if (!formData.meetDate) {
      newErrors.meetDate = "Meet date is required";
    }

    if (!formData.startTime) {
      newErrors.startTime = "Start time is required";
    }
    if (!formData.courseName) {
      newErrors.courseName = "Course Name is required";
    }

    if (!formData.endTime) {
      newErrors.endTime = "End time is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = {
      title: formData.title,
      link: formData.link,
      courseName:formData.courseName,
      meetDate: `${formData.meetDate.toISOString().split("T")[0]} ${
        formData.startTime
      } - ${formData.endTime}`,
    };

    // Uncomment and use the service to create the meet
    AdminService.createMeet(payload).then((res) => {
      if (res.status === "Fail") {
        toast.error(res.error);
      } else {
        toast.success("Successfully Registered");
        navigate("/support/meetslist");
      }
    });
  };

  const handleCancel = () => {
    navigate("/support/meetslist");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Create Meet</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add title</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.title ? "is-invalid" : ""
                    }`}
                    placeholder="Add title"
                    name="title"
                    onChange={handleInput}
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add Link</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.link ? "is-invalid" : ""
                    }`}
                    placeholder="Add Link"
                    name="link"
                    onChange={handleInput}
                  />
                  {errors.link && (
                    <div className="invalid-feedback">{errors.link}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                {/* <div className="form-group mt-3">
                  <label className="register-font">Course Name</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    placeholder="Course Name"
                    name="courseName"
                    onChange={handleInput}
                  />
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div> */}
                  <div className="form-group mt-3">
                  <label className="register-font">Domain Of Interest</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    onChange={handleInput}
                    name="courseName"
                  >
                    <option>Select Courses</option>
                    {courses.map((ele: any, index) => {
                      return (
                        <option value={ele.name} key={index}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label className="register-font">Meet Date</label>
                  <br/>
                  <DatePicker
                    selected={formData.meetDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    className={`form-control mt-2 ${
                      errors.meetDate ? "is-invalid" : ""
                    }`}
                  />
                  {errors.meetDate && (
                    <div className="invalid-feedback">{errors.meetDate}</div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label className="register-font">Start Time</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.startTime ? "is-invalid" : ""
                    }`}
                    name="startTime"
                    onChange={handleInput}
                  >
                    <option value="">Select Start Time</option>
                    {timeOptions.map((time, index) => (
                      <option value={time} key={index}>
                        {time}
                      </option>
                    ))}
                  </select>
                  {errors.startTime && (
                    <div className="invalid-feedback">{errors.startTime}</div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group mt-3">
                  <label className="register-font">End Time</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.endTime ? "is-invalid" : ""
                    }`}
                    name="endTime"
                    onChange={handleInput}
                  >
                    <option value="">Select End Time</option>
                    {timeOptions.map((time, index) => (
                      <option value={time} key={index}>
                        {time}
                      </option>
                    ))}
                  </select>
                  {errors.endTime && (
                    <div className="invalid-feedback">{errors.endTime}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateMeet;
