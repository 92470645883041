import React from "react";
import image1 from "../../assets/images/home_logos/1.jpeg";
import image2 from "../../assets/images/home_logos/2.jpeg";
import image3 from "../../assets/images/home_logos/3.jpeg";
import image4 from "../../assets/images/home_logos/4.jpeg";

const Recognized = () => {
  return (
    <div>
      <h5 className="medium-header pt-3">Recognized By</h5>
      <div className="text-center me-3 me-sm-0 ms-3 ms-sm-0 mt-3 mb-5">
        <img src={image1} alt="" className="me-3 me-md-5 mt-2 mb-2 rounded" />
        <img src={image2} alt="" className="me-0 me-sm-3 me-md-5 mt-2 mb-2 rounded" />
        <img src={image3} alt="" className="me-3 me-md-5 mt-2 mb-2 rounded" />
        <img src={image4} alt="" className="me-0 me-sm-3 me-md-0 mt-2 mb-2 rounded" />
      </div>
    </div>
  );
};

export default Recognized;
