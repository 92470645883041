import React, { useEffect, useState } from "react";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const RelatedDomain = (props: any) => {
  const [courseData, setCourseData] = useState<any>([]);
  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
    getCoursesList();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getCoursesList = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getCourseList(data).then((res: any) => {
      if (res.status === "Success") {
        setCourseData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };
  const handleClick = (item: any) => {
    navigate(`/program-details`, { state: item });
    scrollToTop();
  };
  return (
    <div>
      <div className="d-flex justify-content-center position-relative mb-5">
        <div className="col-12 col-lg-10 mb-5">
          {courseData.slice(0, 3).map((domain: any, index: number) => (
            <div
              key={index}
              className="col-md-6 col-lg-4 col-xl-4 mb-3 float-start"
            >
              <div key={index} className="card primary-bg text-white mx-md-2">
                <img
                  src={domain.image}
                  className="card-img-top trending-domain-card-image"
                  alt="..."
                />
                {/* <div className="card-body border-top-0 border rounded-bottom border-white border-top-0">
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex">
                      <button className="bg-transparent text-white border border-white px-2 py-1">
                        Development
                      </button>
                      <button className="bg-transparent text-white border border-white mx-2 px-2 py-1">
                        Intermediate
                      </button>
                    </div>
                    <div>
                      <button className="secondary-bg px-2 py-1 py-0">
                        {domain?.soldSeats}/{domain?.totalSeats} people
                      </button>
                    </div>
                  </div>
                  <h5 className="card-title text-start">{domain.courseName}</h5>
                  <p className="card-text secondary-color text-start">
                    {domain.description}
                  </p>
                  <div className="text-start">
                    <button
                      className="bg-white text-start px-4 py-2 secondary-btn"
                      onClick={() => handleClick(domain)}
                    >
                      Learn More
                    </button>
                  </div>
                </div> */}
                <div className="card-content text-start">
                  <div className="tags">
                    <div className="tag">Development</div>
                    <div className="tag my-0 my-lg-2">Intermediate</div>
                    <div className="seat my-lg-2">
                      <i className="fa-regular fa-user"></i> {domain?.soldSeats}
                      /{domain?.totalSeats} Seats
                    </div>
                  </div>
                  <h3 className="card-title">{domain.courseName}</h3>
                  <p className="card-text">{domain.description}</p>
                  <div className="mt-3">
                    <button
                      className="btn-learn-more"
                      onClick={() => handleClick(domain)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedDomain;
