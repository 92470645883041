import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { toast } from "react-toastify";
import { UserService } from "../../../app/service/user.service";

const UserTraining = () => {
  const [selectValue, setSelectValue] = useState("");
  const [textHere, setTextHere] = useState("");
  let topics: any = ["Academics", "Projects", "Trending technologies"];
  const [careerGuidanceData, setCareerGuidanceData] = useState<any>([]);

  const handleSelect = (e: any) => {
    setSelectValue(e.target.value);
  };

  useEffect(() => {
    getCareerGuidance();
  }, []);

  const getCareerGuidance = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole.email;
    const data = {
      email: email,
    };
    UserService.careerGuidanceList(data)
      .then((res: any) => {
        if (res.status === "Success") {
          setCareerGuidanceData(res.data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
      });
  };

  const handleAsk = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole.email;
    const data = {
      emailAddress: email,
      selectTopic: selectValue,
      textHere: textHere,
    };
    setSelectValue("");
    setTextHere("");
    UserService.createCareerGuidance(data)
      .then((res: any) => {
        if (res.status === "Success") {
          toast.success(res.message);
          getCareerGuidance(); // Fetch updated data after a successful submission
        }
      })
      .catch((error) => {
        toast.error("An error occurred while fetching data.");
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg mt-5 px-5">
            <div className="col-md-6">
              <div className="form-group mt-3">
                <label className="register-font">Select topic</label>
                <select
                  className="form-control mt-2"
                  name="degreeYear"
                  onChange={handleSelect}
                >
                  <option>Select topic</option>
                  {topics.map((ele: any, index: number) => {
                    return (
                      <option value={ele} key={index}>
                        {ele}
                      </option>
                    );
                  })}
                </select>
              </div>
              {selectValue && (
                <div className="mt-4">
                  <textarea
                    className="form-control"
                    placeholder="Enter your text here"
                    value={textHere}
                    onChange={(e) => setTextHere(e.target.value)}
                  />
                  <button className="mt-3 btn btn-success" onClick={handleAsk}>
                    Ask Mentor
                  </button>
                </div>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                {careerGuidanceData?.length > 0 &&
                  careerGuidanceData.map((item: any, index: number) => {
                    return (
                      <div className="row mb-3" key={index}>
                        <div className="col-md-4">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">Topic</h5>
                              <p className="card-texts">{item.selectTopic}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">Query</h5>
                              <p className="card-texts">{item.textHere}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title">Status</h5>
                              <p className="card-texts">{item.statusReply || "Waiting for mentor reply"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTraining;
